<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader to="/seller/menu" headerText="Reembolsar Ticket" />
      </sequential-entrance>

      <div class="p-5">
        <b-card bg-variant="light" header="Datos del Ticket" class="">
          <b-card-text
            ><strong>Referencia: </strong>{{ ticket.reference }}</b-card-text
          >
          <b-card-text
            ><strong>Cupo (cantidad de entradas): </strong
            >{{ ticket.quota }}</b-card-text
          >
          <b-card-text
            ><strong>Vendedor: </strong
            >{{ ticket.seller.fullname }}</b-card-text
          >
        </b-card>

        <b-card bg-variant="light" header="Datos del pago" class="mt-3">
          <b-card-text
            ><strong>Pago a través de: </strong
            >{{
              ticket.payment_method == 'mcp'
                ? 'Macro Click de Pago'
                : ticket.payment_method == 'mp'
                ? 'Mercado Pago'
                : 'Otro'
            }}</b-card-text
          >
          <b-card-text
            ><strong>ID de pago: </strong
            >{{ ticket.transaction.payment_id }}</b-card-text
          >
          <b-card-text
            ><strong>Método de pago: </strong
            >{{ ticket.transaction.payment_method }}</b-card-text
          >
          <b-card-text
            ><strong>Nombre del pagador: </strong
            >{{
              ticket.transaction.response_complete.InformacionPagador.Nombre
            }}</b-card-text
          >
          <b-card-text
            ><strong>Fecha de pago: </strong
            >{{
              idoDateToDateAndHours(ticket.transaction.updated_at)
            }}</b-card-text
          >
        </b-card>

        <b-card bg-variant="light" header="Monto" class="mt-3">
          <b-card-text
            ><strong>Monto: </strong>${{
              formatPrice(ticket.transaction.amount)
            }}</b-card-text
          >
          <b-card-text
            ><strong>Comisión: </strong>${{
              formatPrice(ticket.transaction.fee)
            }}</b-card-text
          >
          <b-card-text
            ><strong>Total: </strong>${{
              formatPrice(ticket.transaction.amount + ticket.transaction.fee)
            }}</b-card-text
          >
        </b-card>
      </div>

      <div class="w-100">
        <b-button class="mx-3" block variant="primary" @click="refundTicket()"
          >Solicitar reembolso</b-button
        >
      </div>

      <h3 class="p-1 text-center" v-if="!ticket_uuid || !event_id">
        Debes acceder a esta página desde el listado de "Todos los Links"
        únicamente (en caso que tengas permiso)
      </h3>
    </div>
  </Animated>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  refundEcommerceMCPTicket,
  getTicketWithTransaction,
} from '@/services/seller.service'
import { idoDateToDateAndHours } from '@/helpers/date'

export default {
  data() {
    return {
      ticket_uuid: this.$route.params.ticket_uuid,
      event_id: this.$route.params.event_id,
      ticket: null,
    }
  },
  computed: {},
  methods: {
    async refundTicket() {
      try {
        const body = {
          event_id: this.event_id,
          TransaccionComercioId: this.ticket.uuid,
        }

        const response = await refundEcommerceMCPTicket(body)

        if (response.data.status == 200) {
          Vue.$toast.success(response.data.message)

          setTimeout(() => {
            this.$router.push('/seller/menu')
          }, 2000)
        } else {
          Vue.$toast.error(response.data.message)
        }
      } catch (error) {
        Vue.$toast.error(ErrorMessages.COMMON)
      }
    },
    async getTicketWithTransaction() {
      const response = await getTicketWithTransaction(
        this.event_id,
        this.ticket_uuid
      )

      this.ticket = response.data
    },
    formatPrice(value) {
      const val = (value / 1).toFixed().replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    idoDateToDateAndHours(date) {
      return idoDateToDateAndHours(date)
    },
  },
  mounted() {
    this.getTicketWithTransaction()
  },
  components: {
    SellerHeader,
  },
}
</script>

<style lang="scss" scope>
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}
</style>
